import { ArticleList } from '@sprinx-app/ui-commerce';
import Image from 'next/image';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import ProductCardStyled from '@/components/product-card-styled';
import type { ProductCardStyledProduct } from '@/components/product-card-styled';
import classNames from '@/lib/class-names';
import articleListClasses from '../styles/modules/article-list.module.css';
import contentCatalogueUrl from './content-catalogue-url';
import contentNewsDetailUrl from './content-news-detail-url';
import contentNewsListUrl from './content-news-list-url';
import type { NewsListItem } from './content-news-list-data';

export type ContentHomePageProps = {
  hero: ContentHomePageHeroProps['hero'];
  newsArticles: ContentHomePageNewsProps['newsArticles'];
  promotionProducts: ContentHomePagePromotionProductsProps['promotionProducts'];
  topCategories: ContentHomePageTopCategoriesProps['topCategories'];
  whyUs: ContentHomePageWhyUsProps['whyUs'];
};

export default function ContentHomePage(props: ContentHomePageProps) {
  return (
    <>
      <ContentHomePageHero hero={props.hero} />
      <ContentHomePageTopCategories topCategories={props.topCategories} />
      <ContentHomePagePromotionProducts promotionProducts={props.promotionProducts} />
      <ContentHomePageNews newsArticles={props.newsArticles} />
      <ContentHomePageWhyUs whyUs={props.whyUs} />
    </>
  );
}

// *****************************************
// *
// * ContentHomePageHero
// *
// *****************************************

type ImageDescriptor = {
  alt: string;
  height: number;
  src: string;
  width: number;
};

type ContentHomePageHeroProps = {
  hero: {
    image: ImageDescriptor;
  };
};

function ContentHomePageHero({ hero }: ContentHomePageHeroProps) {
  return (
    <div className='relative'>
      <div aria-hidden='true' className='bg-surface-suspend absolute hidden h-full w-1/2 lg:block' />
      <div className='bg-surface-suspend relative lg:bg-transparent'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-2 lg:px-8'>
          <div className='mx-auto max-w-2xl py-24 lg:max-w-none lg:py-40'>
            <div className='lg:pr-16'>
              <p className='text-secondary text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl'>
                <span>{'We'}</span>
              </p>
              <p className='text-primary text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl'>
                <span>{'Believe'}</span>
              </p>
              <p className='text-secondary text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl'>
                <span className='text-secondary'>{'Věříme, že prostřednictvím inovací najdeme řešení.'}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='h-48 w-full sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2'>
        <Image
          alt={hero.image.alt}
          className='h-full w-full object-cover object-center'
          height={hero.image.height}
          src={hero.image.src}
          width={hero.image.width}
        />
      </div>
    </div>
  );
}

// *****************************************
// *
// * ContentHomePageTopCategories
// *
// *****************************************

type TopCategory = {
  description: string | null;
  hash: string;
  image: ImageDescriptor | null;
  name: string;
};

type ContentHomePageTopCategoriesProps = {
  topCategories: TopCategory[];
};

function ContentHomePageTopCategories({ topCategories }: ContentHomePageTopCategoriesProps) {
  const intl = useIntl();

  return (
    <section aria-labelledby='top-categories-heading'>
      <div className='py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:pb-12 lg:pt-24'>
        <div className='flex items-center justify-between px-4 sm:px-6 lg:px-0'>
          <h2 className='heading-section' id='top-categories-heading'>
            {intl.formatMessage({
              defaultMessage: 'Oblíbené kategorie',
              description: "Home page section 'TOP Categories' title.",
              id: 'MGxPgn',
            })}
          </h2>
        </div>

        <div className='mt-8 flow-root'>
          <div className='-my-2'>
            <div className='relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible'>
              <nav
                aria-describedby='top-categories-heading'
                className={'mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0'}
              >
                {topCategories.map((category) => (
                  <Link
                    key={category.hash}
                    className='relative inline-flex h-80 w-64 flex-col overflow-hidden rounded-lg p-6 text-center hover:opacity-75 lg:w-auto'
                    href={contentCatalogueUrl(category.hash, category.name)}
                    prefetch={false}
                  >
                    <span aria-hidden='true' className='absolute inset-0'>
                      {category.image != null && (
                        <Image
                          alt=''
                          className='h-full w-full object-cover object-center'
                          height={category.image.height}
                          src={category.image.src}
                          width={category.image.width}
                        />
                      )}
                    </span>
                    <span
                      aria-hidden='true'
                      className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
                    />
                    <span className='relative mt-auto text-center text-xl font-bold text-white'>{category.name}</span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// *****************************************
// *
// * ContentHomePageArrivalProducts
// *
// *****************************************

type ContentHomePagePromotionProductsProps = {
  promotionProducts: ProductCardStyledProduct[];
};

function ContentHomePagePromotionProducts({ promotionProducts }: ContentHomePagePromotionProductsProps) {
  const intl = useIntl();

  return (
    <section aria-labelledby='top-promoted-products'>
      <div className='py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:py-12 lg:px-8'>
        <div className='flex items-center justify-between px-4 sm:px-6 lg:px-0'>
          <h2 className='heading-section' id='top-promoted-products'>
            {intl.formatMessage({
              defaultMessage: 'Novinky v sortimentu',
              description: "Home page section 'Arrival products' title.",
              id: 'Uxf53P',
            })}
          </h2>
        </div>
        <div className='relative mt-8'>
          <div className='relative w-full overflow-x-auto'>
            <ul className='mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0'>
              {promotionProducts.map((product) => (
                <ProductCardStyled key={`promotion-products-${product.sku}`} as='li' {...product} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

// *****************************************
// *
// * ContentHomePageNews
// *
// *****************************************

type ContentHomePageNewsProps = {
  newsArticles: NewsListItem[];
};

function ContentHomePageNews({ newsArticles }: ContentHomePageNewsProps) {
  const intl = useIntl();

  return (
    <section aria-labelledby='trending-heading' className='bg-surface'>
      <div className='py-16 sm:py-24 lg:mx-auto lg:max-w-7xl lg:py-12 lg:px-8'>
        <div className='flex items-center justify-between px-4 sm:px-6 lg:px-0'>
          <h2 className='heading-section' id='trending-heading'>
            {intl.formatMessage({
              defaultMessage: 'Zprávy',
              description: 'Home page news section title.',
              id: 'OKn0cd',
            })}
          </h2>
          <Link className='link link-sharp link-bold hidden text-sm sm:block' href={contentNewsListUrl()}>
            {intl.formatMessage({
              defaultMessage: 'Zobrazit další příspěvky',
              description: 'Show all news action link label.',
              id: 'Ym8noH',
            })}
            <span aria-hidden='true'> &rarr;</span>
          </Link>
        </div>

        <div className='relative mt-8'>
          <div className='relative w-full overflow-x-auto pb-2'>
            <ArticleList
              as='ul'
              className={'mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0'}
            >
              {newsArticles.map((article) => (
                <ArticleList.Item
                  key={article.path}
                  as='li'
                  className='inline-flex w-64 flex-col text-center lg:w-auto'
                  href={contentNewsDetailUrl(article.slug)}
                >
                  <div className='group relative'>
                    <ArticleList.Item.Image
                      alt={article.title}
                      className={articleListClasses.itemImage}
                      src={article.teaserImage}
                    />
                    <ArticleList.Item.Heading className={articleListClasses.itemHeading}>
                      {article.title}
                    </ArticleList.Item.Heading>
                    <ArticleList.Item.Text className={classNames(articleListClasses.itemText, 'last:!mb-0')}>
                      {article.teaserText}
                    </ArticleList.Item.Text>
                  </div>
                </ArticleList.Item>
              ))}
            </ArticleList>
          </div>
        </div>

        <div className='mt-12 px-4 sm:hidden'>
          <Link className='link link-sharp link-bold text-sm' href={contentNewsListUrl()}>
            {intl.formatMessage({
              defaultMessage: 'Zobrazit další příspěvky',
              description: 'Show all news action link label.',
              id: 'Ym8noH',
            })}
            <span aria-hidden='true'> &rarr;</span>
          </Link>
        </div>
      </div>
    </section>
  );
}

// *****************************************
// *
// * ContentHomePageWhyUs
// *
// *****************************************

type WhyUsItem = {
  description: string | null;
  image: ImageDescriptor | null;
  title: string | null;
};

type ContentHomePageWhyUsProps = {
  whyUs: WhyUsItem[];
};

function ContentHomePageWhyUs({ whyUs }: ContentHomePageWhyUsProps) {
  return (
    <section aria-labelledby='trending-heading' className='bg-surface'>
      <div className='pt-16 sm:pt-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:pt-12'>
        <div className='flex items-center justify-between px-4 sm:px-6 lg:px-0'>
          <h2 className='heading-section'>Proč BLYTH?</h2>
        </div>
      </div>
      <div className='mt-8 pb-16 sm:pb-24 lg:mx-auto lg:max-w-7xl lg:px-8 lg:pb-12'>
        <dl className='grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
          {whyUs.map((item) => (
            <div key={item.title} className='relative pl-16'>
              <dt className='text-base font-semibold leading-7 text-gray-900'>
                <div className='absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600'>
                  {item.image != null && (
                    <Image
                      alt={item.image.alt}
                      aria-hidden='true'
                      // data-imagefit={imageFit}
                      height={item.image.height}
                      src={item.image.src}
                      // unoptimized={unoptimized}
                      width={item.image.width}
                    />
                  )}
                </div>
                {item.title}
              </dt>
              <dd className='mt-2 text-base leading-7 text-gray-600'>{item.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
}
